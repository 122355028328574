import React from "react"
import CalendarLeaf from "./calendar-leaf"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import PropTypes from "prop-types"

const EventPreview = (props) => {
  const event = props.event;
  const startDate = new Date(event.start.dateTime);
  const endDate = new Date(event.end.dateTime);

  return (
    <Row className={`align-items-center ${props.className}`}>
      <Col sm={5}>
        <div className="d-flex justify-content-center">
          <CalendarLeaf start={startDate} end={endDate} small={true}/>
        </div>
      </Col>
      <Col sm={7} className="text-center text-sm-left mt-2 mt-sm-0">
        <h3>{event.summary}</h3>
      </Col>
    </Row>
  )
}

EventPreview.propTypes = {
  className: PropTypes.string,
  event: PropTypes.any
}

EventPreview.defaultProps = {
  className: ``,
  event: undefined,
}

export default EventPreview;
