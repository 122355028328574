import React from "react"
import PropTypes from "prop-types"
import { truncate } from "../utils/text"

function toPlainText(blocks = []) {
  if (!blocks) {
    return '';
  }
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children,
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n\n')
}

const SanityExcerpt = ({ blocks, maxLength }) => {
  return (
    <p>{truncate(toPlainText(blocks), maxLength)}</p>
  )
}

SanityExcerpt.propTypes = {
  blocks: PropTypes.any,
  maxLength: PropTypes.number
}

SanityExcerpt.defaultProps = {
  blocks: undefined,
  maxLength: 150
}

export default SanityExcerpt;
