import React from "react"
import Link from "./link"
import PropTypes from "prop-types"
import SanityExcerpt from "./sanity-excerpt"

const NewsPostPreview = props => {
  const post = props.post
  const small = props.small
  return (
    <div
      className={`news-post-preview ${small ? "news-post-preview-small" : ""} ${
        props.className
      }`}
    >
      <p className="news-post-preview-date font-color-medium">
        {post.publishedAt}
      </p>
      <h3>
        <Link to={`/news/${post.slug.current}`}>{post.title}</Link>
      </h3>
      {post.isNewsV1 ? (
        <p>{post.parent.excerpt}</p>
      ) : (
        <SanityExcerpt blocks={post.parent.body} />
      )}
    </div>
  )
}

NewsPostPreview.propTypes = {
  post: PropTypes.any,
  small: PropTypes.bool,
  className: PropTypes.string,
}

NewsPostPreview.defaultProps = {
  post: undefined,
  small: false,
  className: ``,
}

export default NewsPostPreview
