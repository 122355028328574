import Jumbotron from "react-bootstrap/Jumbotron"
import React from "react"
import Container from "react-bootstrap/Container"
import { FaExternalLinkAlt } from "react-icons/fa"
import Image from "./image"
import PropTypes from "prop-types"

const Hero = ({ title, subtitle, cta }) => {
  return (
    <Jumbotron fluid>
      <Container style={{ maxWidth: "1024px" }}>
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-center justify-content-lg-start mb-3">
          <Image className="logo mr-md-2" />
          <h1 className="m-0 whitespace-preline text-center text-sm-left">
            {title}
          </h1>
        </div>
        <p className="text-center text-lg-left whitespace-preline">
          {subtitle}
        </p>
        <div className="d-flex justify-content-center justify-content-lg-start">
          <a
            className="btn btn-primary btn-main"
            target="_blank"
            rel="noopener noreferrer"
            href={cta.url.href}
          >
            <div className="d-flex align-items-center">
              {cta.title}
              <FaExternalLinkAlt className="ml-2" />
            </div>
          </a>
        </div>
      </Container>
    </Jumbotron>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.object,
}

Hero.propDefaults = {
  title: "Title",
  subtitle: "Subtitle",
  cta: {
    title: "Click here",
    url: { blank: false, href: "https://example.com" },
  },
}

export default Hero
