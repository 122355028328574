import React from "react"
import PropTypes from "prop-types"

const Banner = ({ children, image }) => {

  const background = image ? {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.75)), url(${ image.asset.url })`
  } : {};

  return (
    <section className="banner d-flex flex-column justify-content-end" style={background}>
      {children}
    </section>
  )
};

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.object,
}

Banner.propDefaults = {
  title: 'Title',
  subtitle: 'Subtitle',
  cta: { title: 'Click here', url: { blank: false, href: 'https://example.com' } }
}

export default Banner
